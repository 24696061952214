import { Button, Grid, Theme, Typography, useTheme } from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Box, styled, SxProps } from '@mui/system';
import { graphql, Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { LatestNewsCategoryPageQueryQuery } from '../../graphql-types';
import { IconFilter } from '../assets/icons';
import { ImageHouse } from '../assets/images';
import { CAPTIONS_TEXT_COLOR } from '../colors';
import { HeroSection } from '../components';
import Layout from '../components/layout';
import { NewsCard } from '../components/news-card';
import { FlexBox } from '../components/primitives/flex';
import { Width80Box } from '../components/primitives/Width80Box';
import { disablePointerEvent, linkStyle } from '../constants';
import LatestNewsView from './views/latest-news-view';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center'
});

export interface LatestNewsPageProps {
  data: LatestNewsCategoryPageQueryQuery;
  pageContext: any;
}

const LatestNewsPage = (props: LatestNewsPageProps) => {
  const { data, pageContext } = props;
  const parentUrl = `/latest-news/category/${pageContext.tagSlug}`;

  return <LatestNewsView data={data} parentUrl={parentUrl} pageContext={pageContext} />;
};

export const query = graphql`
  query LatestNewsCategoryPageQuery($skip: Int!, $limit: Int!, $tagId: String!) {
    allCraftGqlNewsTagsTag {
      nodes {
        title
        slug
        id
      }
    }
    allCraftGqlLatestNewsDefaultEntry(
      sort: { fields: postDate, order: DESC }
      filter: { newsTags: { elemMatch: { id: { eq: $tagId } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          heroBackgroundImage {
            url
          }
          title
          postDate
          newsTags {
            ... on CraftGQL_newsTags_Tag {
              id
              title
            }
          }
        }
      }
    }
  }
`;

LatestNewsPage.Layout = Layout;
export default LatestNewsPage;
